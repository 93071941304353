import React, { useState } from 'react';
import { submitPullRequest } from '../api';

const SubmitPullRequestPage = () => {
  const [data_type, setDataType] = useState('test'); // Default to 'test'

  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  const handleSubmit = async () => {
    if (window.confirm("Are you sure you want to submit a pull request?")) {
      try {
        const response = await submitPullRequest(data_type); // Pass the data_type
        if (response.status === 200) {
          alert("Pull request submitted successfully.");
        } else {
          alert("Failed to submit pull request.");
        }
      } catch (error) {
        alert("An error occurred while submitting the pull request.");
        console.error(error);
      }
    }
  };

  return (
    <div>
      <h1>Submit Pull Request</h1>
      <label>
        Data Type:
        <select value={data_type} onChange={handleDataTypeChange}>
          <option value="test">Test</option>
          <option value="data">Training Data</option>
        </select>
      </label>
      <button onClick={handleSubmit}>Submit Pull Request</button>
    </div>
  );
};

export default SubmitPullRequestPage;
