import axios from 'axios';

const getJWTToken = () => {
  // Get the CF_Authorization cookie
  const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('CF_Authorization='));
  return tokenCookie ? tokenCookie.split('=')[1] : null;
};

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Cf-Access-Jwt-Assertion': getJWTToken(),
  },
});

export const getPrompts = async (data_type) => {
  try {
    return await axiosInstance.get(`/prompts?data_type=${data_type}`);
  } catch (error) {
    throw error;
  }
};

export const getPrompt = (id, data_type) =>
  axiosInstance.get(`/prompts/${id}`, {
    params: { data_type },
  });

export const createPrompt = async (data_type, prompt) => {
  try {
    return await axiosInstance.post(`/prompts?data_type=${data_type}`, prompt);
  } catch (error) {
    throw error;
  }
};

export const updatePrompt = (id, data_type, prompt) =>
  axiosInstance.put(`/prompts/${id}`, prompt, {
    params: { data_type },
  });

export const deletePrompt = async (id, data_type) => {
  try {
    return await axiosInstance.delete(`/prompts/${id}?data_type=${data_type}`);
  } catch (error) {
    throw error;
  }
};

export const submitPullRequest = async (data_type) => {
  try {
    return await axiosInstance.post(`/pr?data_type=${data_type}`);
  } catch (error) {
    throw error;
  }
};
