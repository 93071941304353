import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import HomePage from './components/HomePage';
import PromptListPage from './components/PromptListPage';
import PromptDetailPage from './components/PromptDetailPage';
import PromptEditPage from './components/PromptEditPage';
import CreatePromptPage from './components/CreatePromptPage';
import SubmitPullRequestPage from './components/SubmitPullRequestPage';
import DeletePromptPage from './components/DeletePromptPage';

function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/prompts/:data_type" element={<PromptListPage />} />
        <Route path="/prompts/:data_type/:id" element={<PromptDetailPage />} />
        <Route path="/prompts/:data_type/:id/edit" element={<PromptEditPage />} />
        <Route path="/create" element={<CreatePromptPage />} />
        <Route path="/pull-request" element={<SubmitPullRequestPage />} />
        <Route path="/prompts/:data_type/:id/delete" element={<DeletePromptPage />} />
      </Routes>
    </Router>
  );
}

export default App;
