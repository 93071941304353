import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPrompts } from '../api';
import { Link } from 'react-router-dom';
import usePagination from '../usePagination';

const fetchPrompts = async (data_type, setPrompts) => {
    try {
        const response = await getPrompts(data_type);
        
        const prompts = response.data.data.prompts;
        setPrompts(prompts);
    } catch (error) {
        console.error('Failed to fetch prompts:', error);
    }
};

const PromptListPage = () => {
    const { data_type } = useParams();
    const [prompts, setPrompts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const filteredPrompts = (prompts || []).filter(prompt =>
        prompt.id.includes(searchQuery) || prompt.category.includes(searchQuery) || prompt.scenario.includes(searchQuery)
    );
    
    const { next, prev, currentData, currentPage, maxPage } = usePagination(filteredPrompts, itemsPerPage);

    useEffect(() => {
        fetchPrompts(data_type, setPrompts);
    }, [data_type]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div>
            <h1>Current {data_type} Prompts</h1>
            <input type="text" value={searchQuery} onChange={handleSearch} placeholder="Search by ID or Category" />
            
                <label htmlFor="itemsPerPage">Items per page: </label>
                <select id="itemsPerPage" value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={100}>100</option>
                </select>

                <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Category</th>
                        <th>Scenario</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentData().map(prompt => (
                        <tr key={prompt.id}>
                            <td>{prompt.id}</td>
                            <td>{prompt.category}</td>
                            <td>{prompt.scenario}</td>
                            <td>
                                <Link to={`/prompts/${data_type}/${prompt.id}`}>View</Link>
                                {' | '}
                                <Link to={`/prompts/${data_type}/${prompt.id}/edit`}>Edit</Link>
                                {' | '}
                                <Link to={`/prompts/${data_type}/${prompt.id}/delete`}>Delete</Link> {/* Delete link added */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <button onClick={prev}>Previous</button>
                <button onClick={next}>Next</button>
                <span>Page: {currentPage} of {maxPage}</span>
            </div>
        </div>
    );
};

export default PromptListPage;