import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getPrompt, updatePrompt } from '../api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Function to remove HTML tags
const stripHtmlTags = (str) => {
  if ((str === null) || (str === '')) return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, '');
};

const PromptEditPage = () => {
  const { id, data_type } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [prompt, setPrompt] = useState({
    id: '',
    category: '',
    scenario: '',
    context: [{ prompt: '', answer: '' }],
  });

  // New state variables for "Category" and "Scenario"
  const [category, setCategory] = useState('');
  const [scenario, setScenario] = useState('');

  const fetchPrompt = useCallback(async () => {
    try {
      const response = await getPrompt(id, data_type);
      const promptData = response.data.data;
      if (promptData) {
        setPrompt(promptData);
        setCategory(promptData.category);  // Initialize state variables
        setScenario(promptData.scenario);  // Initialize state variables
      } else {
        setPrompt({
          id: '',
          category: '',
          scenario: '',
          context: [{ prompt: '', answer: '' }],
        });
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [id, data_type]);

  useEffect(() => {
    fetchPrompt();
  }, [fetchPrompt]);

  const handleChange = (e, index, name) => {
    const value = e;
    const list = [...prompt.context];
    list[index][name] = value;
    setPrompt(prevState => ({ ...prevState, context: list }));
  };

  const handleRemoveClick = index => {
    const list = [...prompt.context];
    list.splice(index, 1);
    setPrompt(prevState => ({ ...prevState, context: list }));
  };

  const handleAddClick = () => {
    setPrompt(prevState => ({ ...prevState, context: [...prevState.context, { prompt: '', answer: '' }] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Remove HTML tags before submission
    const updatedPrompt = {
      category: category,
      scenario: scenario,
      context: prompt.context.map(item => ({
        prompt: stripHtmlTags(item.prompt),
        answer: stripHtmlTags(item.answer),
      })),
    };

    await updatePrompt(id, data_type, updatedPrompt);
    navigate(`/prompts/${data_type}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <style>
        {`
          .ql-container.ql-snow {
            border: 2px solid black !important;
          }
          .ql-editor {
            background-color: white !important;
          }
        `}
      </style>
      <form onSubmit={handleSubmit}>
        <h1>
          <strong>
            {data_type === 'test' ? 'Edit Test Prompt' : 'Edit Training Data Prompt'}
          </strong>
        </h1>
        <label>
          Category:
          <input type="text" value={category} onChange={e => setCategory(e.target.value)} pattern="[a-z ]+" title="Only lowercase characters and spaces are allowed." />
        </label>
        <label>
          Scenario:
          <input type="text" value={scenario} onChange={e => setScenario(e.target.value)} pattern="[a-z ]+" title="Only lowercase characters and spaces are allowed." />
        </label>
        {prompt.context.map((x, i) => {
          return (
            <div className="box" key={i}>
              <ReactQuill value={x.prompt} onChange={(val) => handleChange(val, i, 'prompt')} />
              <ReactQuill value={x.answer} onChange={(val) => handleChange(val, i, 'answer')} />
              {prompt.context.length !== 1 && <button className="mr10" onClick={() => handleRemoveClick(i)}>Remove</button>}
              {prompt.context.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
            </div>
          );
        })}
        <button type="submit">Update</button>
        <Link to={`/prompts/${data_type}/${prompt.id}/delete`}>Delete Prompt</Link>
      </form>
    </>
  );
  
};

export default PromptEditPage;
