import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { deletePrompt } from '../api';

const DeletePromptPage = () => {
  const { id, data_type } = useParams();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await deletePrompt(id, data_type);
      alert('Prompt deleted successfully.');
      navigate(`/prompts/${data_type}`);
    } catch (error) {
      alert('Failed to delete prompt.');
      console.error(error);
    }
  };

  const handleCancel = () => {
    navigate(`/prompts/${data_type}/${id}`);
  };

  return (
    <div>
      <h1>Are you sure you want to delete this prompt?</h1>
      <button onClick={handleDelete}>Delete</button>
      <button onClick={handleCancel}>Cancel</button>
    </div>
  );
};

export default DeletePromptPage;
