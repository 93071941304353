import React from 'react';
import { Link } from 'react-router-dom';

const NavigationBar = () => {
    return (
        <nav>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/prompts/test">Test Prompts</Link></li> {/* Updated URL */}
                <li><Link to="/prompts/data">Training Data Prompts</Link></li> {/* Updated URL */}
                <li><Link to="/create">Create New Prompt</Link></li>
                <li><Link to="/pull-request">Submit Pull Request</Link></li>
            </ul>
        </nav>
    );
};

export default NavigationBar;
