import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPrompt } from '../api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Function to remove HTML tags
const stripHtmlTags = (str) => {
  if ((str === null) || (str === '')) return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, '');
};

const CreatePromptPage = () => {
  const navigate = useNavigate();
  
  const [data_type, setDataType] = useState('test');
  const [prompt, setPrompt] = useState({
    category: '',
    scenario: '',
    context: [{ prompt: '', answer: '' }],
  });

  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  const handleChange = (e, index, name) => {
    const value = e;
    const list = [...prompt.context];
    list[index][name] = value;
    setPrompt(prevState => ({ ...prevState, context: list }));
  };

  const handleRemoveClick = index => {
    const list = [...prompt.context];
    list.splice(index, 1);
    setPrompt(prevState => ({ ...prevState, context: list }));
  };

  const handleAddClick = () => {
    setPrompt(prevState => ({ ...prevState, context: [...prevState.context, { prompt: '', answer: '' }] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Remove HTML tags before submission
    const sanitizedPrompt = {
      ...prompt,
      context: prompt.context.map(item => ({
        prompt: stripHtmlTags(item.prompt),
        answer: stripHtmlTags(item.answer),
      })),
    };

    try {
      await createPrompt(data_type, sanitizedPrompt);
      navigate(`/prompts/${data_type}`);
    } catch (error) {
      console.error('Failed to create prompt:', error);
    }
  };

  return (
    <>
      <style>
        {`
          .ql-container.ql-snow {
            border: 2px solid black !important;
          }
          .ql-editor {
            background-color: white !important;
          }
        `}
      </style>
      <form onSubmit={handleSubmit}>
        <h1>Create Prompt</h1>
        <label>
          Data Type:
          <select value={data_type} onChange={handleDataTypeChange}>
            <option value="test">Test</option>
            <option value="data">Training Data</option>
          </select>
        </label>
        <input name="category" placeholder="Enter Category" value={prompt.category} onChange={e => setPrompt({ ...prompt, category: e.target.value })} pattern="[a-z ]+" title="Only lowercase characters and spaces are allowed." />
        <input name="scenario" placeholder="Enter Scenario" value={prompt.scenario} onChange={e => setPrompt({ ...prompt, scenario: e.target.value })} pattern="[a-z ]+" title="Only lowercase characters and spaces are allowed." />
        {prompt.context.map((x, i) => {
          return (
            <div className="box" key={i}>
              <ReactQuill value={x.prompt} onChange={(val) => handleChange(val, i, 'prompt')} />
              <ReactQuill value={x.answer} onChange={(val) => handleChange(val, i, 'answer')} />
              {prompt.context.length !== 1 && <button className="mr10" onClick={() => handleRemoveClick(i)}>Remove</button>}
              {prompt.context.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
            </div>
          );
        })}
        <button type="submit">Create Prompt</button>
      </form>
    </>
  );
};

export default CreatePromptPage;
