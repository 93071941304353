import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getPrompt } from '../api';

const fetchPrompt = async (id, data_type, setPrompt) => {
    try {
        const response = await getPrompt(id, data_type);
        setPrompt(response.data.data); // Accessing the prompt directly
    } catch (error) {
        console.error('Failed to fetch prompt:', error);
    }
};

const PromptDetailPage = () => {
    const { id, data_type } = useParams(); // Including data_type in useParams
    const [prompt, setPrompt] = useState(null);

    useEffect(() => {
        fetchPrompt(id, data_type, setPrompt);
    }, [id, data_type]);

    return (
        <div>
            {prompt ? (
                <>
                    <h1>Prompt Details</h1>
                    <p><strong>Category:</strong> {prompt.category}</p>
                    <p><strong>ID:</strong> {prompt.id}</p>
                    <p><strong>Scenario:</strong> {prompt.scenario}</p>
                    <h2>Context:</h2>
                    {prompt.context && prompt.context.map((item, index) => (
                        <div key={index}>
                            <p><strong>Prompt:</strong> {item.prompt}</p>
                            <p><strong>Answer:</strong> {item.answer}</p>
                        </div>
                    ))}
                    <Link to={`/prompts/${data_type}/${prompt.id}/edit`}>Edit Prompt</Link> {/* Including data_type in the URL */}
                    <Link to={`/prompts/${data_type}/${prompt.id}/delete`}>Delete Prompt</Link> {/* Including data_type in the URL */}

                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default PromptDetailPage;
